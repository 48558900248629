import { useEffect, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Stack, Typography } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import EntitySelect from 'Commons/select/entitySelectNew';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { useDispatch, useSelector } from 'store';
import RoleGuard from 'components/RoleGuard';
import { openDrawer } from 'store/slices/menu';
import { changeDefaultEntityId } from 'store/slices/user';
import useAuth from 'hooks/useAuth';
// assets
import { IconMenu2 } from '@tabler/icons';
import { useLocation, useSearchParams } from 'react-router-dom';
import { openSnackbar } from 'store/slices/snackbar';
import useNetworkStatus from 'utils/useNetworkStatus';
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const isOnline = useNetworkStatus();
  const theme = useTheme();

  const dispatch = useDispatch();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { defaultEntityId } = useSelector((state) => state.user);
  const { user } = useAuth();
  const [network, setNetwork] = useState(true)

  useEffect(() => {
    if (!isOnline) {
      setNetwork(false)
      dispatch(
        openSnackbar({
          open: !isOnline,
          message: 'No internet connection',
          variant: 'alert',
          alert: {
            color: 'error',
          },
        })
      );
    }

    if (isOnline && !network) {
      dispatch(
        openSnackbar({
          open: isOnline,
          message: 'Connection established successfully',
          variant: 'alert',
          autoHideDuration: 6000,
          alert: {
            color: 'success',
          },
        })
      );
    }
  }, [isOnline, dispatch]);


  useEffect(() => {
    if (window.sessionStorage?.roles === 'entityPrincipalAdmin') {
      dispatch(
        changeDefaultEntityId(window.sessionStorage.getItem('entityId'))
      );
    }
  }, [window.sessionStorage, location]);
  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('lg')]: {
            width: 'auto',
          },
        }}
      >
        {/* Logo Section */}
        <Box
          component='span'
          sx={{ display: { xs: 'none', lg: 'block' }, flexGrow: 1 }}
        >
          <Stack direction='row' alignItems='center'>
            <LogoSection dark />
            <Typography
              variant='h3'
              sx={{
                marginLeft: 2,
              }}
            >
              SPG
            </Typography>
          </Stack>
        </Box>
        {/* Togggle Button Section */}
        <Avatar
          variant='rounded'
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            overflow: 'hidden',
            transition: 'all .2s ease-in-out',
            background:
              theme.palette.mode === 'dark'
                ? theme.palette.dark.main
                : theme.palette.secondary.light,
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.secondary.main
                : theme.palette.grey[900],
            '&:hover': {
              background:
                theme.palette.mode === 'dark'
                  ? theme.palette.secondary.main
                  : theme.palette.paper,
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.secondary.light
                  : theme.palette.grey[900],
              boxShadow: 'none',
            },
            display: { lg: 'none', xs: 'flex' },
            justifyContent: 'start',
            height: '48px',
            width: '48px',
          }}
          onClick={() => dispatch(openDrawer(!drawerOpen))}
          color='inherit'
        >
          <IconMenu2 stroke={1.5} size='1.3rem' />
        </Avatar>
      </Box>
      {/* Entity Select Autocomplete Field Goes Here */}
      {(user?.entities || window.sessionStorage.getItem('entityId')) && (
        <RoleGuard permissionOn='entity' permissionOnType='fetch'>
          <EntitySelect />
        </RoleGuard>
      )}
      {/* Notificaiton and Profile Sections */}
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
      >
        {/* Notification Section */}
        <NotificationSection />
        {/* Profile Section */}
        <ProfileSection />
      </Box>
    </>
  );
};

export default Header;
