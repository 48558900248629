import React, { useMemo, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import Customization from '../Customization';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { getDefaultEntityId } from 'store/slices/user';

// assets
import { IconChevronRight } from '@tabler/icons';

import { fetchRecord } from 'services/AxiosAdmin';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      // padding: 20,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
      [theme.breakpoints.up('lg')]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down('lg')]: {
        marginLeft: '10px',
        width: `calc(100% - ${drawerWidth}px)`,
        // padding: '16px',
        marginRight: '10px',
      },
    }),
    ...(open && {
      // padding: 20,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('lg')]: {
        // padding: '16px',
        marginLeft: '10px',
        marginRight: '10px',
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const ps = useRef();
  function psScrollTop() {
    const curr = ps.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  }
  const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { drawerOpen, scrollTop } = useSelector((state) => state.menu);
  const { container } = useConfig();

  if (sessionStorage.getItem('entityId')) {
    const checkStatus = async () => {
      let response = await fetchRecord(
        `/admin/entity/${sessionStorage.getItem('entityId')}`
      );

      if (response.status === 200) {
        let entityStatus = response.data.data.isEntityActive;
        if (!entityStatus) {
          sessionStorage.clear();
          window.close();
          // navigate('/login');
        }
        // setEntityData(response.data.data);
      } else {
        // history('/entities');
      }
    };
    checkStatus();
  }

  useEffect(() => {
    dispatch(openDrawer(!matchDownLg));
  }, [matchDownLg]);

  useEffect(() => {
    dispatch(getDefaultEntityId());
  }, []);
  useEffect(() => {
    psScrollTop();
  }, [navigate, scrollTop]);
  const header = useMemo(
    () => (
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Header />
      </Toolbar>
    ),
    []
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position='fixed'
        color='inherit'
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create('width') : 'none',
        }}
      >
        {header}
      </AppBar>

      {/* drawer */}
      <Sidebar />

      {/* main content */}
      <Main theme={theme} open={drawerOpen} sx={{ padding: '20px 10px' }}>
        <PerfectScrollbar
          component='div'
          style={{
            height: 'calc(100vh - 148px)',
            padding: '0 10px',
          }}
          containerRef={(el) => (ps.current = el)}
          className='perfect-scroll-bar'
        >
          {/* breadcrumb */}
          {container && (
            <Container maxWidth='lg'>
              <Breadcrumbs
                separator={IconChevronRight}
                navigation={navigation}
                icon
                title
                rightAlign
              />
              <Outlet />
            </Container>
          )}
          {/* Content */}
          {!container && (
            <>
              <Breadcrumbs
                separator={IconChevronRight}
                navigation={navigation}
                icon
                title
                rightAlign
              />
              <Outlet />
            </>
          )}
        </PerfectScrollbar>
      </Main>
      {/* <Customization /> */}
    </Box>
  );
};

export default MainLayout;
