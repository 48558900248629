// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  Typography,
} from '@mui/material';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { chatDateTimeHelper } from 'utils/dateTimeHelper';
import { Delete } from '@mui/icons-material';
import { deleteRecord } from 'services/AxiosAdmin';
import { useDispatch, useSelector } from 'store';
import { changeDefaultEntityId } from 'store/slices/user';
import { IconChevronDownLeft } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import { API, fetchRecord, postRecord } from 'services/AxiosAdmin';
import { activeItem } from 'store/slices/menu';
import { useNavigate } from 'react-router-dom';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  position: 'relative',
  // '&:hover': {
  //   background:
  //     theme.palette.mode === 'dark'
  //       ? theme.palette.dark.main
  //       : theme.palette.primary.light,
  // },
  '& .MuiListItem-root': {
    padding: 0,
  },
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({
  notifications,
  getUnreadCount,
  getNotifications,
  setNotifications,
}) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [hover, setHover] = useState();
  const navigate = useNavigate();

  const chipSX = {
    height: 24,
    padding: '0 6px',
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.primary.main,
    marginRight: '5px',
  };

  // const chipWarningSX = {
  //   ...chipSX,
  //   color: theme.palette.warning.dark,
  //   backgroundColor:
  //     theme.palette.mode === 'dark'
  //       ? theme.palette.dark.main
  //       : theme.palette.warning.light,
  // };

  const handleMouseEnter = (notificationId) => {
    setHover(notificationId);
  };

  const handleMouseLeave = () => {
    setHover();
  };

  const handleDeleteClick = async (notificationId) => {
    const response = await deleteRecord(
      `/admin/notifications/${notificationId}`
    );
    if (response.status === 204) {
      getNotifications();
      getUnreadCount();
    }
  };

  const markAsRead = async (notificationId) => {
    let response = await postRecord(
      `/admin/notifications/mark-as-read/${notificationId}`
    );
    if (response.status === 200) {
      getNotifications();
      getUnreadCount();
    }
  };

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300,
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22,
        },
        '& .MuiDivider-root': {
          my: 0,
        },
        '& .list-container': {
          pl: 1,
        },
      }}
    >
      {notifications.map((notification) => (
        <React.Fragment key={notification?._id}>
          <ListItemWrapper
            style={{
              backgroundColor:
                !notification?.isRead && notification.isCritical
                  ? 'rgba(233, 15, 15, 0.2)'
                  : !notification?.isRead && '#F2F2F2',
            }}
          >
            <Grid
              container
              direction='column'
              className='list-container'
              onMouseOver={() => handleMouseEnter(notification?._id)}
              onMouseOut={handleMouseLeave}
              onClick={() => {
                if (
                  sessionStorage.getItem('entityId') === null &&
                  user.role === 'entityPrincipalAdmin'
                ) {
                  markAsRead(notification._id);
                  dispatch(changeDefaultEntityId(notification.data.entity));
                  if (notification.type === 'emergency') {
                    navigate(`/emergency/${notification.data._id}`, {
                      state: {
                        title: `${
                          notification.data.status === 1 ? 'Active' : 'Past'
                        } emergencies`,
                      },
                    });
                    dispatch(activeItem(['emergencies']));
                  }
                } else {
                  markAsRead(notification._id);
                  if (
                    notification.type === 'emergency' &&
                    user.role === 'entityPrincipalAdmin'
                  ) {
                    navigate(`/emergency/${notification.data._id}`, {
                      state: {
                        title: `${
                          notification.data.status === 1 ? 'Active' : 'Past'
                        } emergencies`,
                      },
                    });
                    dispatch(activeItem(['emergencies']));
                  }
                }
              }}
            >
              <Typography variant='subtitle1'>{notification?.title}</Typography>

              <Grid item xs={5} sx={{ pb: 2 }}>
                <Typography variant='body1'>{notification?.default}</Typography>
              </Grid>
              <Grid item xs={12} justifyContent='space-between'>
                <Grid container direction='row' justifyContent='space-between'>
                  {/* {!notification?.isRead && (
                    <Grid item>
                      <Chip label='Unread' sx={chipErrorSX} />
                    </Grid>
                  )} */}
                  <Grid item>
                    <Typography variant='caption' display='block' gutterBottom>
                      {chatDateTimeHelper(notification?.createdAt)}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <p
                      style={{
                        margin: '0px',
                        color: '#b80d01',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeleteClick(notification._id)}
                    >
                      Delete
                    </p>
                  </Grid>
                </Grid>
                {/* <Grid
                  container
                  text
                  sx={
                    {
                      // position: 'absolute',
                      // top: 0,
                      // left: 0,
                      // height: '100%',
                      // width: '100%',
                    }
                  }
                >
                  <IconButton
                    onClick={() => handleDeleteClick(notification._id)}
                    sx={{
                      display: 'block',
                    }}
                  >
                    <Delete color='secondary' />
                  </IconButton>
                </Grid> */}
              </Grid>
            </Grid>
          </ListItemWrapper>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default NotificationList;
